import {
  ref,
  onMounted,
  reactive,
  watch,
  getCurrentInstance,
  onBeforeUnmount
} from 'vue'
import {
  companyUseCase
} from '@/domain/usecase'
import ModalCompany from '@/views/master/customers/company/modal/ModalCompany.vue'
import { useRoute, useRouter } from 'vue-router'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import { useStore } from 'vuex'
import { isEmpty } from 'lodash'
import $ from 'jquery'

export default {
  name: 'Company',
  components: {
    ModalCompany
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $toast,
      $socketHub,
      $swal,
      $strInd
    } = app!.appContext.config.globalProperties
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const dataForm = ref()
    const op = ref()
    const id = ref()
    const columns = [{
      title: 'Nama Pelanggan'
    },
    {
      title: 'Kota'
    },
    {
      title: 'Kode Referral'
    },
    {
      title: 'Email'
    }, {
      title: 'No. Telp'
    }, {
      title: 'Jenis Perusahaan'
    }, {
      title: ''
    }]
    const dataSource = ref([]) as any
    const data = ref([])
    const totalRecords = ref(0)
    const isShowModalCompany = ref(false)
    const fromModal = ref(null)
    const inputSearch = ref(route.query.CompanyName)

    const mappingData = (value: any) => {
      /* eslint-disable-next-line */
      value.map((x: any) => {
        // const item = null
        x.Level = 0
        const mapData = x
        dataSource.value.push(mapData)
        /* eslint-disable-next-line */
        x.Branchs.map((val: any) => {
          val.Level = 1
          const tmp = val
          dataSource.value.push(tmp)
        })
        // console.log(dataSource.value)
      })
    }
    const getAllData = () => {
      store.dispatch('showLoading')
      companyUseCase.getMinimalInfo(window.location.search).then((response) => {
        if (!response.error) {
          data.value = response.result
          console.log(response.result)
          totalRecords.value = response.result.Count
          dataSource.value = []
          mappingData(response.result.Data)
        } else {
          $toast.add({
            severity: 'error',
            detail: response.message,
            group: 'bc',
            life: 3000
          })
        }
        store.dispatch('hideLoading')
      })
    }

    const goToDetail = (val: any) => {
      router.push({
        name: 'master-company-detail',
        params: {
          id: encryptDecriptMethods.encrypt(val.Id)
        }
      })
    }

    const nameEmpty = (val: any) => {
      if (isEmpty(val)) {
        return '-'
      } return val
    }

    const openModal = (from: any, val: any) => {
      fromModal.value = from
      isShowModalCompany.value = true
    }

    const onPage = () => {
      getAllData()
    }

    const showDropDown = (evt: any, val: any) => {
      op.value.toggle(evt)
      dataForm.value = val
    }

    const hideModalCompany = () => {
      isShowModalCompany.value = false
    }

    const isActived = async (val: any) => {
      store.dispatch('showLoading')
      const userid = val.LoginId
      const dataList = [{
        op: 'replace',
        path: '/isActive',
        value: val.IsActive
      }]
      const response = await companyUseCase.changeIsActiveV2(userid, dataList)
      if (response.error) {
        val.IsActive = !val.IsActive
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorChangeStatus, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'success', detail: 'Berhasil merubah status aktif', group: 'bc', life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const changeIsActive = (val: any) => {
      $swal.fire({
        title: 'Status Aktif',
        text: $strInd.confirm.msgChange,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F44336',
        cancelButtonColor: '#90A4AE',
        allowOutsideClick: false,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result: any) => {
        if (result.isConfirmed) {
          isActived(val)
        }
        if (result.isDismissed) {
          val.IsActive = !val.IsActive
        }
      })
    }

    const isActivateTop = (val: any, form: any) => {
      store.dispatch('showLoading')
      companyUseCase.changeIsPayLater(form.Id, {
        IsCanPaylater: val
      }).then((response: any) => {
        if (response.error) {
          $toast.add({
            severity: 'error', detail: response.message, group: 'bc', life: 1500
          })
        } else {
          $toast.add({
            severity: 'success', detail: `${response.result.title ?? response.result.Title} ${response.result.detail ?? response.result.Detail}`, group: 'bc', life: 1500
          })
          getAllData()
        }
        store.dispatch('hideLoading')
      })
    }

    const changeActivateTOP = (val: any, form: any) => {
      $swal.fire({
        title: 'Status TOP',
        text: $strInd.confirm.msgChange,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F44336',
        cancelButtonColor: '#90A4AE',
        allowOutsideClick: false,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result: any) => {
        if (result.isConfirmed) {
          isActivateTop(val, form)
        }
      })
    }

    const submitSearch = (val: any) => {
      router.replace({
        // name: route.name,
        query: {
          CompanyName: val
        }
      }).then(() => {
        getAllData()
      }).catch(() => {
        console.log()
      })
    }

    const showChild = (val: any) => {
      id.value = `.child-${val}`
      $(id.value).toggle()
      // console.log(this)
      $(`#icon-${val}`, this).toggleClass('pi pi-angle-right pi pi-angle-down')
    }

    onMounted(() => {
      $socketHub.on('reloadCompany', getAllData)
      getAllData()
      // showChild(id)
      // mappingData()
    })

    onBeforeUnmount(() => {
      $socketHub.off('reloadCompany')
    })
    return {
      dataSource,
      goToDetail,
      columns,
      nameEmpty,
      totalRecords,
      onPage,
      route,
      showDropDown,
      dataForm,
      op,
      isShowModalCompany,
      openModal,
      hideModalCompany,
      fromModal,
      changeIsActive,
      submitSearch,
      inputSearch,
      showChild,
      changeActivateTOP
    }
  }
}
